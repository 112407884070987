'use client';

import errorReporter from '@/actions/error-reporter';
import { useEffect } from 'react';

export default function GlobalError({ error }: { error: Error }) {
  useEffect(() => {
    async function sendError() {
      await errorReporter(error.stack);
    }

    sendError();
  }, [error]);
}
